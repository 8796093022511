<template>
  <div>
    <div class="w-content">
      <form @submit.prevent="performTransacHandler">
        <h2 class="title">Delegation</h2>
        <div class="mb-20">{{$t("Acc_Desc_15")}}</div>
        <label class="d-block mb-10">{{$t("Acc_Desc_7")}}</label>
        <input class="main-input"
               id="Address"
               placeholder="Enter Wallet Address here"
               v-model.trim="address"
               :disabled="disabledField" />
        <span class="textColorRed"
              id="ErrorAddress"
              v-if="$v.address.$dirty && !$v.address.required">{{$t("Acc_input_1_error")}}</span>
        <label class="d-block mb-10">{{$t("Acc_Desc_8")}}</label>
        <input :disabled="disabledField"
               class="main-input"
               id="Amount"
               placeholder="0.0"
               v-model="amount" />
        <span class="textColorRed"
              id="ErrorAmount"
              v-if="$v.amount.$dirty && !$v.amount.required">{{$t("Acc_input_2_error")}}</span>
        <!--        <p class="textColorRed" id="ErrorAmount"></p>-->
        <label class="d-block mb-10">{{$t("Acc_Desc_9")}}</label>
        <input :disabled="disabledField"
               class="main-input"
               id="Fee"
               placeholder="0.0"
               v-model="fee" />
        <div class="mb-20">
          <input type="radio" v-model="delegate" value="1" @change="onChange()" v-bind:true-value="1" /> Delegate Funds <br>
          <input type="radio" v-model="delegate" value="2" @change="onChange()" v-bind:true-value="2" /> Revoke Delegation <br>
          <input type="radio" v-model="delegate" value="3" @change="onChange()" v-bind:true-value="3" /> Delegation till <br>
        </div>
        <label class="d-block mb-10">{{$t("Acc_Desc_21")}}</label>
        <div>
          <input v-if="timeEnabled"
                 class="main-input"
                 id="Till"
                 v-model="delegateTime" />
        </div>
        <!--        <p class="textColorRed mb-50" id="ErrorUserData"></p>-->
        <button :disabled="disabledField" type="submit" class="bold-link bttn">{{$t("Acc_button_1")}}</button>
      </form>
    </div>
    <!-- <div class="w-content" v-else>
      <h2 class="title">!!!!!!! MESSAGE ACCOUNT LOGIN</h2>
    </div>-->
  </div>
</template>

<style>
  .link {
    margin: 10px;
  }
</style>

<script>
  import {required} from "vuelidate/lib/validators";
  import {mapState} from "vuex";
  import instance from "../http";
  import { TheMask } from 'vue-the-mask'
  import { formatUTC, now } from "core-js/fn/date";

  export default {
    name: "perform-transaction",

    data: () => ({
      address: "",
      amount: "",
      fee: 0.1,
      userData: "",
      userContracts: "",
      tokenPublicKey: "CS",
      responseTransactionPackData: {},
      methodApi: "TransferCs",
      disabledField: false,
      visible: false,
      show: false,
      delegate: 1,
      delegateTime: "",
      timeEnabled: false,
      on: true,
      off: false
    }),
    validations: {
      address: { required },
      amount: { required }
    },
    methods: {
      performTransacHandler() {
        if (this.$v.$invalid === true) {
          this.$v.$touch();
          console.log("adress is required");
          return;
        }

        let _amount = this.amount.toString().replace(',','.');
        let _fee = this.fee.toString().replace(',', '.');
        var timestamp = "";
        if (this.delegateTime != "") {
          var tillTime = Date.parse(this.delegateTime);
          timestamp = (Math.round(tillTime / 1000.0)).toString();
          console.log("timestamp: ", timestamp);
        }

        console.log("delegation till ", this.delegateTime)
        let requestApiModel = {
          PublicKey: this.publicKey,
          ReceiverPublicKey: this.address,
          TokenPublicKey: this.tokenPublicKey,
          Amount: parseFloat(_amount),
          UserData: this.userData,
          MethodApi: this.methodApi,
          Fee: parseFloat(_fee),
          NetworkAlias: this.networkAlias,
          DelegateEnable: this.delegate == 1 || this.delegate == 3,
          DelegateDisable: this.delegate == 2,
          DateExpiredUtc: timestamp
        };
        let postContent = JSON.stringify(requestApiModel);
        let headers = {
          "Content-Type": "application/json"
        };
        let _this = this;
        console.log("requestAPIMODEL:");
        console.log(requestApiModel);
        new Promise(function(resolver, reject) {
          _this.disabledField = true;
          // _this.$loading(true);
          instance
            .post("../../api/wallet/packtransaction", requestApiModel, { headers })
            .then(request => {
              console.log("RESPONSE:");
              console.log(request.data);
              resolver(request);
            })
            .catch(ex => {
              // _this.$loading(false);
              console.log(ex);
              reject(ex);
            });
        }).then(d => {
          // this.$emit("performTransactionUpdate");
          console.log("HELLO FROM PACK TR:");
          console.log(d.data);
          // this.responseTransactionPackData = d.data;
          this.$store.commit("SET_transactionPackData", requestApiModel);
          this.$store.commit("SET_responseTransactionPack", d.data);
          this.$router.push({
            path: "../wallet/delegation-result"
          });
        });
      },
      onChange() {
        if (this.delegate == "1" || this.delegate == "2") {
          this.timeEnabled = false;
          this.delegateTime = "";
          console.log("Del time = ", this.delegateTime);
        } else {
          this.timeEnabled = true;
          var now = Date();
          var dateFormat = require("dateformat");
          this.delegateTime = dateFormat(now, "isoDate") + " " + dateFormat(now, "isoTime");
          console.log("Del time = " , this.delegateTime);
        }
        
      }
      //onChangeSelect(e) {
      //  this.tokenPublicKey = e;
      //  if (this.tokenPublicKey === "" || this.tokenPublicKey === null) {
      //    this.methodApi = "TransferCs";
      //  } else {
      //    this.methodApi = "TransferToken";
      //  }
      //  console.log(e);
      //}
    },

    computed: {
      //getter
      ...mapState({
        publicKey: state => state.publicKey,
        privateKey: state => state.privateKey,
        balanceList: state => state.balanceList,
        networkAlias: s => s.networkAlias
      })
    },
    components:{TheMask}
  };
</script>
